import React from "react"
import './index.scss'
import {Link} from "react-router-dom";
import pJson from '../../../package.json'

const FooterOne = () => {
    return (
        <div className='footer footer-one' style={{height: '100%'}}>
            <div className="footer-content">
                <div className="top">
                    <div className="column column-1">
                        <Link to='/' className='logo-container'>
                            <img
                                src={'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png'}
                                alt="Happy Solutions Logo"
                                className='logo-img'
                            />
                            <h3 className='t-l'>
                                Happy Solutions
                            </h3>
                        </Link>
                        <p>
                            Happy Solutions Éttermi és Vendéglátós szoftver, raktárkezelő, webáruház vendéglátó, éttermi
                            egysége számára.
                            Happy, Solutions, Étterem, Éttermek, Éttermi szoftver, NTAK, integráció, Foodora, Wolt,
                            ételrendelés, házhozszállítás, Vendéglátós, Vendéglátós szoftver, Bár szoftver,
                            Felszolgáló, Felszolgáló szoftver, Digitális stand, Digitális vendéglátás, Restaurants, POS,
                            terminal, pos terminal, restaurant, restaurant system, restaurant pos
                        </p>
                        <div className="links">
                            <a href="mailto:info@happygastro.hu">
                                <i className="bx bx-mail-send"/>
                            </a>
                            <a href="tel:+3613009105">
                                <i className="bx bx-phone"/>
                            </a>
                            <a href="https://www.facebook.com/gastro.happy" target='_blank' rel="noreferrer">
                                <i className="bx bxl-facebook"/>
                            </a>
                        </div>
                        <div className="aszf">
                            <Link to={'/aszf'}>
                                Általános Szerződési Feltételek
                            </Link>
                        </div>
                        <div className="adatkezelesi-tajekoztato">
                            <Link to={'/adatkezelesi-tajekoztato'}>
                                Adatkezelési tájékoztató
                            </Link>
                        </div>
                        <div className="karrier">
                            <Link to={'/karrier'}>
                                Karrier
                            </Link>
                        </div>
                        <div className="partner-program">
                            <Link to={'/partner-program'}>
                                Partner program
                            </Link>
                        </div>
                        <div className="gyik">
                            <Link to={'/kapcsolat/gyik'}>
                                GyIK
                            </Link>
                        </div>
                        <div className="integrations">
                            <Link to={'/integraciok'}>
                                Integrációk
                            </Link>
                        </div>
                        <br/>
                    </div>
                    <div className="column column-3">
                        <h3>
                            Elérhetőségek
                        </h3>
                        <div className="links">
                            <a href="https://goo.gl/maps/T3hRZM87rtbpGRiY7" target={'_blank'} rel="noreferrer">
                                <i className="bx bx-map-pin"/>
                                <strong>
                                    Szentendre, Duna korzó 11, 2000
                                </strong>
                            </a>
                            <a href="tel:+3613009105">
                                <i className="bx bx-phone-call"/>
                                <strong>
                                    +36 (1) 300 9105
                                </strong>
                            </a>
                            <a href="mailto:info@happygastro.hu">
                                <i className="bx bx-info-circle"/>
                                <strong>
                                    info@happygastro.hu
                                </strong>
                            </a>
                            <a href="mailto:support@happygastro.hu">
                                <i className="bx bx-wrench"/>
                                <strong>
                                    support@happygastro.hu
                                </strong>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <strong>Copyright © 2020-{new Date().getFullYear()} Happy Gastro Kft. - Minden jog
                        fenntartva.</strong>
                    <div className='happy'>
                        <strong>
                            Üzemelteti a
                        </strong>
                        <div className="watermark">
                            <img src={'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png'}
                                 alt="Happy Solutions"/>
                            <span onClick={(e)=>{}}>
                            Happy Solutions<sup style={{fontSize: '7px', color: '#f1b44c'}}>™</sup>
                        </span>
                        </div>
                        <strong>
                            szoftvere
                        </strong>
                        <strong>
                            !
                        </strong>
                    </div>
                    <small>v{pJson.version}</small>
                </div>
            </div>
        </div>
    )
}

export default FooterOne;
